import * as Sentry from "@sentry/react";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { hasAtLeast } from "remeda";

import type { GetUserResponse } from "../apis/api-types";
import { CURRENT_ORGANIZATION } from "../constants/localStorage";
import { useEvent } from "./useEvent";

type MultiLDContext = {
  kind: "multi";
  user: {
    key: string;
    email: string;
    name: string;
  };
  organization?: {
    key: string;
    name: string;
  };
};

/**
 * Assigns context to the LaunchDarkly and Sentry sessions with the current user's details
 */
export default function useAssignContexts() {
  const ldClient = useLDClient();

  const assignContexts = useEvent((userJson: GetUserResponse) => {
    try {
      const { user, organizations } = userJson;

      if (ldClient) {
        const ldContext: MultiLDContext = {
          kind: "multi",
          user: {
            key: user.user,
            email: user.email,
            name: user.name,
          },
        };

        if (hasAtLeast(organizations, 1)) {
          const currentOrg = organizations.find(
            (org) =>
              org.organization ===
              window.localStorage.getItem(CURRENT_ORGANIZATION),
          );

          if (currentOrg) {
            ldContext.organization = {
              key: currentOrg.organization,
              name: currentOrg.name,
            };
          } else {
            ldContext.organization = {
              key: organizations[0].organization,
              name: organizations[0].name,
            };
          }
        }

        console.info("[LaunchDarkly] Identifying context", ldContext);

        ldClient.identify(ldContext);
      }

      console.info("Sentry Logger [log]: Identifying user", {
        id: user.user,
        email: user.email,
      });

      Sentry.setUser({
        id: user.user,
        email: user.email,
      });
    } catch (err) {
      console.log("Failed to identify user with Sentry and/or LaunchDarkly");

      console.error(err);
    }
  });

  return assignContexts;
}
