export default function ConduitLogomark() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={32}
      height={32}
      fill="none"
      aria-labelledby={"logomark"}
    >
      <title id={"logomark"}>{"Conduit"}</title>
      <path
        fill="currentColor"
        d="M27.473 16.697a5.328 5.328 0 0 0-2.226-.699 5.32 5.32 0 0 0 2.226-.694 5.347 5.347 0 0 0-5.346-9.261 5.306 5.306 0 0 0-1.715 1.58 5.347 5.347 0 1 0-9.677 0 5.325 5.325 0 0 0-1.714-1.58 5.347 5.347 0 0 0-5.347 9.26 5.32 5.32 0 0 0 2.226.695A5.346 5.346 0 0 0 1.717 24a5.347 5.347 0 0 0 9.022.38 5.301 5.301 0 0 0-.512 2.274 5.347 5.347 0 0 0 10.693 0c0-.815-.185-1.584-.511-2.275a5.347 5.347 0 1 0 7.064-7.683Zm-7.303 1.957a5.333 5.333 0 0 0-.284 4.778l-4.312-7.428-4.312 7.428a5.338 5.338 0 0 0-.284-4.778 5.335 5.335 0 0 0-3.996-2.635l8.59-.019-4.278-7.45a5.338 5.338 0 0 0 4.28 2.145c1.75 0 3.305-.843 4.28-2.144L15.576 16l8.59.019a5.337 5.337 0 0 0-3.996 2.635Z"
      />
    </svg>
  );
}
