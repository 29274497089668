import { ErrorBoundary } from "@sentry/react";
import { useSuspenseQuery } from "@tanstack/react-query";
import { Suspense } from "react";

import { useOrganization } from "../context/hooks";
import { handleCustomerPortal } from "../hooks/api/useCustomerPortal";
import { SidebarNavExternalLink } from "./SidebarNavLink";

export default function CustomerBillingSidebarNavLink() {
  return (
    <ErrorBoundary>
      <Suspense fallback={<BillingLinkFallback />}>
        <BillingLink />
      </Suspense>
    </ErrorBoundary>
  );
}

function BillingLink() {
  const organization = useOrganization();

  const { data, error, isFetching } = useSuspenseQuery(
    handleCustomerPortal({ organization: organization.organization }),
  );

  if (error && !isFetching) {
    throw error;
  }

  if (data.Url) {
    return (
      <SidebarNavExternalLink href={data.Url}>Billing</SidebarNavExternalLink>
    );
  }

  return null;
}

function BillingLinkFallback() {
  return (
    <div className="h-8 px-5 py-2.5">
      <div className="skeleton-loader h-3 w-16 rounded" />
    </div>
  );
}
