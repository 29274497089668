export class UserFacingError extends Error {
  name = "UserFacingError" as const;
  message: string;
  cause?: Error;
  code?: number;

  constructor({
    message,
    cause,
    code,
  }: {
    message: string;
    cause?: Error;
    code?: number;
  }) {
    super(message);
    this.message = message;
    this.cause = cause;
    this.code = code;
  }
}

/**
 * @deprecated Seems to bug out
 */
export function assertUserFacingError(
  value: unknown,
): asserts value is UserFacingError {
  if (
    !(
      value instanceof UserFacingError ||
      toString.call(value) === "[object Error]"
    )
  ) {
    throw new TypeError(
      `Expected an \`UserFacingError\`, got \`${JSON.stringify(
        value,
      )}\` (${typeof value})`,
    );
  }
}
