import { QueryClient } from "@tanstack/react-query";
import { hashFn, structuralSharing } from "wagmi/query";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryKeyHashFn: hashFn,
      structuralSharing,
    },
  },
});
