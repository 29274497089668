import type { User as FirebaseUser } from "firebase/auth";
import { createContext, useContext, useEffect, useState } from "react";

import { conduitAPI } from "../apis/conduit-api";
import useAssignContexts from "../hooks/useAssignContexts";
import { useEvent } from "../hooks/useEvent";
import { logOut, onAuthStateChanged } from "../lib/firebase";
import { initialState, useAuthStore } from "../stores/auth-store";
import { getCurrentOrganization } from "../utils/getCurrentOrganization";
import { getNameFromUserObject } from "../utils/getNameFromUserObject";

type EmptyObject = Record<string, never>;

type TAuthContext = {
  user: FirebaseUser | EmptyObject | null;
};

const AuthContext = createContext<TAuthContext>({} as TAuthContext);

export function AuthContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const assignContexts = useAssignContexts();

  const [user, setUser] = useState<FirebaseUser | EmptyObject | null>({});

  function clearUserData() {
    useAuthStore.setState(initialState);
  }

  const authStateChanged = useEvent(
    async (currentUser: FirebaseUser | null) => {
      setUser(currentUser);
      useAuthStore.setState({ user: currentUser });

      if (currentUser) {
        try {
          const userJson = await conduitAPI.loadUser();

          useAuthStore.setState({
            email: userJson.user.email,
            name: getNameFromUserObject(userJson),
            organization: getCurrentOrganization(userJson.organizations),
            organizations: userJson.organizations,
            userObject: userJson,
          });

          assignContexts(userJson);
        } catch (err) {
          console.error(err);

          await logOut();
        }
      } else {
        clearUserData();
      }
    },
  );

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(authStateChanged);

    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthContext.Provider value={{ user }}>{children}</AuthContext.Provider>
  );
}

// eslint-disable-next-line react-refresh/only-export-components
export function useUserAuth() {
  const context = useContext<TAuthContext>(AuthContext);

  if (!context) {
    throw new Error("useUserAuth has to be used within <AuthContextProvider>");
  }

  return context;
}
