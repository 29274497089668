import { NotFoundPanel } from "../shared/NotFoundPanels";
import SystemLayout from "../shared/SystemLayout";

export default function NotFoundPage() {
  return (
    <SystemLayout>
      <NotFoundPanel />
    </SystemLayout>
  );
}
