import { queryOptions, useQuery } from "@tanstack/react-query";

import { conduitAPI } from "../../apis/conduit-api";
import { useOrganization } from "../../context/hooks";

export const handleCustomerPortal = ({
  organization,
}: {
  organization: string | undefined;
}) =>
  queryOptions({
    enabled: !!organization,
    queryKey: ["CustomerPortal", organization] as const,
    async queryFn() {
      try {
        const resp = await conduitAPI.handleCustomerPortal({
          organization: organization!,
          network: undefined,
        });

        return resp;
      } catch {
        return {
          Url: undefined,
        };
      }
    },
    refetchOnWindowFocus: false,
    retry: false,
  });

export default function useCustomerPortal() {
  const organization = useOrganization();

  return useQuery(
    handleCustomerPortal({ organization: organization.organization }),
  );
}
