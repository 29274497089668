import type { VariantProps } from "class-variance-authority";
import { twc, type TwcComponentProps } from "react-twc";

import { button } from "./button.styles";

interface ButtonProps
  extends TwcComponentProps<"button">,
    VariantProps<typeof button> {}

export const Button = twc.button.transientProps([
  "variant",
  "size",
])<ButtonProps>(({ variant, size }) => button({ variant, size }));
