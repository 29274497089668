import { Link } from "react-router-dom";

import { Button } from "./Button";
import { Panel } from "./Panel";

export function NotFoundKeysPanel() {
  return (
    <Panel className="max-h-80 text-center">
      <h1 className="mb-3 text-system-xl">404 Not Found</h1>

      <p className="mb-2 text-system-m text-grey-70">
        Let&apos;s get you back on track.
      </p>

      <Button asChild className="w-full">
        <a href="/nodes">Back to your API Keys</a>
      </Button>
    </Panel>
  );
}

export function NotFoundIntegrationPanel() {
  return (
    <Panel className="max-h-80 text-center">
      <h1 className="mb-3 text-system-xl">404 Not Found</h1>

      <p className="mb-2 text-system-m text-grey-70">
        Let&apos;s get you back on track.
      </p>

      <Button asChild className="w-full">
        <Link to="../../integrations">Back to Apps</Link>
      </Button>
    </Panel>
  );
}

export function NotFoundPanel() {
  return (
    <Panel className="max-h-80 text-center">
      <h1 className="mb-3 text-system-xl">404 Not Found</h1>

      <p className="mb-2 text-system-m text-grey-70">
        Let&apos;s get you back on track.
      </p>

      <Button asChild className="w-full">
        <a href="/deployments">Back to deployments</a>
      </Button>
    </Panel>
  );
}
