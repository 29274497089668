import { Fragment, useId } from "react";

export default function Placeholder({
  count,
  children,
}: {
  count: number;
  children: React.ReactNode;
}) {
  const id = useId();

  return [...Array.from({ length: count })].map((_, idx) => (
    <Fragment key={`${id}-${idx}`}>{children}</Fragment>
  ));
}
