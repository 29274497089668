import { useShallow } from "zustand/shallow";

import {
  addNewOrganization,
  setCurrentOrganization,
  useAuthStore,
} from "../stores/auth-store";
import { useUserAuth } from "./auth-context";

export function useOrganization() {
  return useAuthStore(useShallow((state) => state.organization));
}

export function useOrganizations() {
  return useAuthStore(useShallow((state) => state.organizations));
}

export function useUser() {
  const { user } = useUserAuth();

  return user;
}

export function useUserObject() {
  return useAuthStore(useShallow((state) => state.userObject));
}

export function useUserId(): string | undefined {
  const userObject = useUserObject();

  return userObject?.user?.user ?? undefined;
}

export function useAddNewOrganization() {
  return addNewOrganization;
}

export function useSetCurrentOrganization() {
  return setCurrentOrganization;
}
