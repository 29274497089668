export {
  ApiHostUrl,
  ArbitrumExternalNodeDocsUrl,
  BlockscoutCustomizationForm,
  ConduitTerminalUrl,
  ContactUsForm,
  DenoApiUrl,
  DocsUrl,
  EmailAuthURL,
  EnterpriseRPCPlanContactForm,
  FaucetDocsUrl,
  IntegrationsURL,
  JobsURL,
  MarketplaceUrl,
  OptimismExternalNodeDocsUrl,
  PylonSupportForm,
  ReportBugURL,
  RollupVerificationForm,
  RPCKeysDocsUrl,
  ScheduleDemoURLNoRedirect,
};

const DocsUrl = "https://docs.conduit.xyz";

const ScheduleDemoURLNoRedirect =
  "https://conduitxyz.typeform.com/request-demo";

/**
 * @deprecated use PylonSupportForm instead
 */
const ReportBugURL = "https://conduitxyz.typeform.com/to/iNMmgWEw#source=app";

const EnterpriseRPCPlanContactForm =
  "https://conduitxyz.typeform.com/to/M6F9fzo1";

/**
 * @deprecated use PylonSupportForm instead
 */
const ContactUsForm = "https://conduitxyz.typeform.com/to/kGF2oAj1#source=app";

const PylonSupportForm =
  "https://portal.usepylon.com/conduit-xyz/forms/support-request";

const BlockscoutCustomizationForm =
  "https://conduitxyz.typeform.com/custom-explorer";

const RollupVerificationForm = "https://conduitxyz.typeform.com/to/UYTp17YT";

const JobsURL = "https://jobs.ashbyhq.com/Conduit";

const EmailAuthURL: string =
  import.meta.env.VITE_EMAIL_REDIRECT_URL ||
  "https://app.conduit-stg.xyz/email-signin-completion";

const ApiHostUrl: string =
  import.meta.env.VITE_API_HOST ||
  `${window.location.protocol}${window.location.hostname}`;

const DenoApiUrl: string =
  import.meta.env.VITE_DENO_API_HOST || "http://localhost:8000";

const ArbitrumExternalNodeDocsUrl =
  "https://docs.conduit.xyz/guides/run-a-node/arbitrum-node";

const OptimismExternalNodeDocsUrl =
  "https://docs.conduit.xyz/guides/run-a-node/op-stack-node";

const RPCKeysDocsUrl = "https://docs.conduit.xyz/rpc-keys/get-rpc-keys";

const FaucetDocsUrl = "https://docs.conduit.xyz/guides/sepolia-eth";

const IntegrationsURL = "https://integrations.conduit.xyz";

const ConduitTerminalUrl =
  import.meta.env.VITE_CONDUIT_TERMINAL_URL ||
  "https://terminal.conduit-stg.xyz";

const MarketplaceUrl = "https://www.conduit.xyz/marketplace";
