import { type ClassValue, clsx } from "clsx";
import { createTwc } from "react-twc";
import { twMerge } from "tailwind-merge";

/**
 * @note Using `clsx` + `twMerge` for a complete flexibility (taken from shadcn/ui)
 */
export function cn(...inputs: ClassValue[]) {
  /**
   * @todo update twMerge with a config to handle 'text-system-*' classes
   */
  return twMerge(clsx(inputs));
}

/**
 * @note We named it `twx` to have better autocompletion
 */
export const twx = createTwc({ compose: cn });
