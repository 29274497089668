import { DocumentDuplicateIcon } from "@heroicons/react/24/outline";

import { twx } from "../utils/twx";

export const IconButton = twx.button`flex size-6 shrink-0 items-center justify-center rounded hover:bg-grey-10 focus:bg-grey-10 focus:outline-none focus-visible:bg-grey-10 group-focus:bg-grey-10`;

export const CopyToClipboard = ({
  alt = "Copy to clipboard",
}: {
  alt?: string;
}) => (
  <>
    <span className="sr-only">{alt}</span>
    <DocumentDuplicateIcon className="size-4 text-grey-60" />
  </>
);
