import { Outlet, useParams } from "react-router-dom";

import { pb } from "../../../../lib/pb";
import CustomerBillingSidebarNavLink from "../../../../shared/customer-billing-sidebar-nav-link";
import SidebarNavLink, {
  SidebarNavLinkWrapper,
} from "../../../../shared/SidebarNavLink";

export default function ViewNetworkSettingsLayout() {
  const { id } = useParams();

  return (
    <div className="mt-10 px-5 pb-5 lg:px-10 lg:pb-10">
      <div className="mx-auto w-full max-w-7xl">
        <div className="mb-10">
          <h1 className="text-system-xxl text-black">Deployment settings</h1>
        </div>

        <div className="grid gap-5 md:grid-cols-[minmax(0,_13.75rem)_1fr] lg:gap-12 xl:gap-24">
          <SidebarNavLinkWrapper>
            <SidebarNavLink
              test-id="GeneralSettings"
              to={pb.dashboardSettings({ slugOrId: id! })}
              end
            >
              General
            </SidebarNavLink>

            <SidebarNavLink
              test-id="CustomizationSettings"
              to={pb.dashboardCustomizationSettings({ slugOrId: id! })}
            >
              Customization
            </SidebarNavLink>

            <CustomerBillingSidebarNavLink />
          </SidebarNavLinkWrapper>

          <Outlet />
        </div>
      </div>
    </div>
  );
}
