import ConduitWordmark from "./ConduitWordmark";

export default function SystemLayout({
  children,
  slot,
}: {
  children: React.ReactNode;
  slot?: React.ReactNode;
}) {
  return (
    <>
      <div className="flex flex-1 flex-col items-center justify-center">
        <div className="mb-8">
          <ConduitWordmark />
        </div>

        {children}
      </div>

      {slot}
    </>
  );
}
