import {
  DocsUrl,
  JobsURL,
  PylonSupportForm,
  ScheduleDemoURLNoRedirect,
} from "../constants/urls";
import { Button } from "./Button";

export default function AppFooter() {
  return (
    <footer className="mt-auto shrink-0 px-6 py-5" role="contentinfo">
      <div className="flex flex-wrap items-center gap-6">
        <Button asChild variant="secondary">
          <a href={ScheduleDemoURLNoRedirect} target="_blank">
            Schedule a demo
          </a>
        </Button>

        <div className="flex items-center gap-5">
          <a
            className="text-system-m-plus text-grey-80"
            href={DocsUrl}
            target="_blank"
          >
            Docs
          </a>

          <a
            className="text-system-m-plus text-grey-80"
            href="https://blog.conduit.xyz"
            target="_blank"
          >
            Blog
          </a>

          <a
            className="text-system-m-plus text-grey-80"
            href={JobsURL}
            target="_blank"
          >
            Jobs
          </a>

          <a
            className="text-system-m-plus text-grey-80"
            href={PylonSupportForm}
            target="_blank"
          >
            Contact support
          </a>
        </div>
      </div>
    </footer>
  );
}
