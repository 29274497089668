import type { User } from "firebase/auth";
import { isEmpty } from "remeda";

import { isNull } from "./isNull";

/**
 * @note Should only be used when the user is known to be present, i.e. within the WithUserLayout
 */
export function assertUser(value: unknown): asserts value is User {
  if (isNotUser(value as User | null | Record<string, never>)) {
    console.error(
      `Expected an \`User\`, got \`${JSON.stringify(
        value,
      )}\` (${typeof value})`,
    );

    // throw new TypeError(
    //   `Expected an \`User\`, got \`${JSON.stringify(
    //     value,
    //   )}\` (${typeof value})`,
    // );
  }
}

export function isNotUser(
  value: User | null | undefined | Record<string, never>,
): value is null | Record<string, never> {
  return !value || isNull(value) || isEmpty(value as Record<string, never>);
}

export function isUser(
  value: User | null | undefined | Record<string, never>,
): value is User {
  return !isNotUser(value);
}
