import { create } from "zustand";
import { persist } from "zustand/middleware";
import { useShallow } from "zustand/shallow";

type PreferencesStore = {
  /**
   * Whether or not to show the deleted API keys in the Conduit Nodes page
   */
  showDeletedKeys: boolean;
  /**
   * Shows all non-verified networks in the Browse Rollups page
   */
  showAllNetworks: boolean;
  /**
   * Used to keep in-progress transactions in local-storage if a user sends a transaction and refreshes the page and our API hasn't caught that its been completed yet
   */
  transactions: Partial<Record<string, string>>;
  /**
   * Tracks whether or not the onboarding cards have been dismissed on new rollups
   */
  onboardingCardsDismissed: Partial<Record<string, boolean>>;
  /**
   * Sort & toggle preferences for the Conduit Nodes individual pages
   */
  nodesFilterPrefs: Partial<Record<string, boolean | string>>;
  /**
   * Show the new external hub link in the AppNav after the user has acknowledged the content moved card on the Browse rollups page
   */
  showHubLink: boolean;
};

const initialState: PreferencesStore = {
  showDeletedKeys: false,
  showAllNetworks: false,
  transactions: {},
  onboardingCardsDismissed: {},
  nodesFilterPrefs: {},
  showHubLink: false,
};

export const usePreferencesStore = create<PreferencesStore>()(
  persist(() => ({ ...initialState }), { name: "conduit.preferences" }),
);

export const toggleShowDeletedKeys = () => {
  usePreferencesStore.setState((state) => ({
    showDeletedKeys: !state.showDeletedKeys,
  }));
};

export const setShowHubLink = (value: boolean) => {
  usePreferencesStore.setState({ showHubLink: value });
};

export const useShowDeletedKeys = () => {
  return usePreferencesStore(useShallow((state) => state.showDeletedKeys));
};

export const setShowAllNetworks = (value: boolean) => {
  usePreferencesStore.setState({
    showAllNetworks: value,
  });
};

export const useShowAllNetworks = () => {
  return usePreferencesStore(useShallow((state) => state.showAllNetworks));
};

export const useTransactionById = (key: string) => {
  return usePreferencesStore(useShallow((state) => state.transactions[key]));
};

/**
 * @param key Use a combination of the network Id and a prefix in the case there might be two txs per network
 * @param hash the hash of the transaction
 */
export const setTransaction = (key: string, hash: string) => {
  usePreferencesStore.setState((state) => ({
    transactions: { ...state.transactions, [key]: hash },
  }));
};

export const useOnboardingCardsDismissed = (key: string) => {
  return usePreferencesStore(
    useShallow((state) => state.onboardingCardsDismissed[key]),
  );
};

/**
 *
 * @param key A unique key for the onboarding card and the network
 * @param value
 */
export const setOnboardingCardsDismissed = (key: string, value = true) => {
  usePreferencesStore.setState((state) => ({
    onboardingCardsDismissed: {
      ...state.onboardingCardsDismissed,
      [key]: value,
    },
  }));
};

/**
 * @param key Use a combination of the network Id and a prefix in the case there might be two txs per network
 */
export const useNodesFilterPrefs = <T extends boolean | string>(
  key: string,
  initialValue: T,
): T => {
  return usePreferencesStore(
    useShallow((state) => (state.nodesFilterPrefs[key] as T) ?? initialValue),
  );
};

/**
 * @param key Use a combination of the network Id and a prefix in the case there might be two txs per network
 * @param value
 */
export const setNodesFilterPrefs = (key: string, value: boolean | string) => {
  usePreferencesStore.setState((state) => ({
    nodesFilterPrefs: {
      ...state.nodesFilterPrefs,
      [key]: value,
    },
  }));
};
